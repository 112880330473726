import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/payment/checkoutForm';
import { getMultiLocaleContent, getTimeRange } from 'utils/helper';
import { useTranslation } from 'react-i18next';
import { mainColor } from 'components/theme';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const appearance = {
  labels: 'floating',
  rules: {
    '.Input': {
      color: '#000000',
    },
    '.Input:focus': {
      borderColor: mainColor,
    },
    '.Input::placeholder': {
      color: '#99999',
    },
  },
};

const PaymentForm = (props) => {
  const { registeredSuccessResponse, record } = props;
  const [stripeOptions] = useState({
    // passing the client secret obtained in step 3
    clientSecret: registeredSuccessResponse.paymentIntent.clientSecret,
    // Fully customizable with appearance API.
    appearance: appearance,
  });

  return (
    <Box>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <CheckoutForm registeredSuccessResponse={registeredSuccessResponse} record={record} />
      </Elements>
    </Box>
  );
};

const RsvpFormPayment = (props) => {
  const { record, registeredSuccessResponse, ...rest } = props;
  const { i18n, t } = useTranslation();

  // const formData = useWatch({});
  // console.log('defaultData', defaultData);
  // console.log('record', record);
  // const domain = 'https://dev-event.ocgl.live';
  const domain = process.env.REACT_APP_ASSET_BASE_URL;
  const rsvpData = record?.rsvp;
  const bannerUrl = rsvpData?.banner ? domain + rsvpData?.banner : null;

  return (
    <Box
      {...rest}
      sx={{
        m: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <Typography variant="h5" sx={{ mb: 2 }}>
      Preview
    </Typography> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          border: 1,
          borderRadius: '10px',
          borderColor: 'divider',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bannerUrl})`,
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            pt: `${(9 / 16) * 100}%`,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            p: 2,
            textAlign: 'center',
          }}
        >
          <Typography variant="h4" sx={{ mt: 3 }}>
            {t('register.complete-your-payment')}
          </Typography>
          <Typography variant="h5" sx={{ mt: 3 }}>
            {getMultiLocaleContent(record.title, i18n.language, true)}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            {getTimeRange(record, i18n.language)}
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {t('register.amount-to-pay')}: $
            {registeredSuccessResponse.paymentIntent.amountInCents / 100} (HKD)
          </Typography>

          <Box sx={{ my: 2, mx: 'auto' }}>
            <PaymentForm
              registeredSuccessResponse={registeredSuccessResponse}
              record={record}
            ></PaymentForm>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RsvpFormPayment;
