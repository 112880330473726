import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { Buffer } from 'buffer';

const CheckoutForm = (props) => {
  const { registeredSuccessResponse, record } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [ready, setReady] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/register/${record.id}/return?response=${Buffer.from(
          JSON.stringify(registeredSuccessResponse)
        ).toString('base64')}`,
      },
    });

    if (result.error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onReady={() => setReady(true)} disableLink={true} />
      {ready && (
        <Button
          variant="contained"
          fullWidth
          disabled={!stripe}
          type="submit"
          sx={{
            mt: '40px',
            height: '40px',
            maxWidth: '200px',
            '&.Mui-disabled': {
              color: '#999999',
              backgroundColor: '#ECECEC',
            },
          }}
        >
          {'Confirm and Pay'}
        </Button>
      )}
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
