import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as API from 'api/baseAPI';
import { setGlobalLoading } from 'redux/appSlice';
import RsvpFormPreview from './RsvpFormPreview';
import { Box, ThemeProvider, createTheme } from '@mui/material';
import EventPreview from './EventPreview';
import RsvpFormFinish from './RsvpFormFinish';
import { FormProvider, useForm } from 'react-hook-form';
import { getMultiLocaleContent } from 'utils/helper';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import eventDuckIcon from '../images/logo.svg';
import FormLocaleSwitcher from 'locales/_FormLocaleSwitcher';
import RsvpFormPayment from './RsvpFormPayment';
const mainColor = '#3FBB9B';
const textColor = '#ffffff';

export default function Register(props) {
  let searchParams = new URL(window.location.href.replace('#', '/')).searchParams;
  let advance = searchParams.has('advance');

  const dispatch = useDispatch();
  const params = useParams();
  const [notFound, setNotFound] = useState(false);

  const rsvpRef = useRef({});
  const eventRef = useRef({});
  const themeRef = useRef(
    createTheme({
      palette: {
        primary: { main: mainColor, contrastText: textColor },
      },
      components: {
        MuiOutlinedInput: {
          defaultProps: {
            startAdornment: <Box sx={{ ml: '-12.5px' }} />,
          },
          styleOverrides: {
            root: {
              maxHeight: '40px',
              '& .MuiInputBase-input': {
                paddingLeft: '12px',
              },
            },
          },
        },
      },
    })
  );
  const registeredSuccessResponse = useRef({});

  const [initializedEvent, setInitializedEvent] = useState(false);

  useEffect(() => {
    if (!params.eventId) {
      return;
    }
    dispatch(setGlobalLoading(true));

    API.getEvent(params.eventId)
      .then((response) => {
        if (response && response.data) {
          if (typeof response.data !== 'object') {
            throw new Error('Invalid Event');
          }
          API.getEventV2(params.eventId)
            .then((response) => {
              if (response && response.data) {
                if (typeof response.data !== 'object') {
                  throw new Error('Invalid Event');
                }
                if (!response.data.rsvp) {
                  setNotFound(true);
                  dispatch(setGlobalLoading(false));
                  return;
                }
                rsvpRef.current = response.data.rsvp;
                eventRef.current = response.data;
                setInitializedEvent(true);
                dispatch(setGlobalLoading(false));
              }
            })
            .catch((err) => {
              // logger.error(err);
              setNotFound(true);
              dispatch(setGlobalLoading(false));
            });
        } else {
          throw new Error('Invalid Event');
        }
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [dispatch, params.eventId]);

  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activeStep]);

  const methods = useForm();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (initializedEvent) {
      const title = getMultiLocaleContent(eventRef.current.title, i18n.language);
      if (title !== '') {
        document.title = `${title} | ${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE}`;
      } else {
        document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
      }
    } else {
      document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
    }
  }, [initializedEvent, i18n.language]);

  return (
    <Fragment>
      {notFound ? (
        <main>
          <div style={{ padding: '10px' }}>
            <p>Page Not Found!</p>
          </div>
        </main>
      ) : (
        <Fragment>
          {initializedEvent && (
            <ThemeProvider theme={themeRef.current}>
              <Box
                sx={{
                  maxWidth: '900px',
                  textAlign: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                }}
              >
                {/* HEADER WITH LOGO*/}
                <Box
                  sx={{
                    alignItems: 'center',
                    background: '#FFFFFF',
                    display: 'flex',
                    height: { xs: '40px', md: '50px' },
                    justifyContent: 'space-between',
                    p: '10px',
                    borderBottom: '1px solid #dddddd',
                  }}
                >
                  <Box
                    sx={{
                      color: '#263c90',
                      fontSize: { xs: '16px', md: '32px' },
                      fontWeight: 'bold',
                      height: '100%',
                      my: '0px',
                      display: 'flex',
                      alignItems: 'center',
                      img: {
                        height: '100%',
                      },
                    }}
                  >
                    <img src={eventDuckIcon} alt="icon"></img>
                  </Box>
                  <FormLocaleSwitcher
                    supportedLocales={advance ? ['en', 'zh-Hans', 'zh-Hant'] : ['en']}
                  />
                </Box>

                {/* CONTENT */}
                <FormProvider {...methods}>
                  <Box id="rForm" component="form">
                    {activeStep === 0 && (
                      <EventPreview
                        record={{ ...eventRef.current, rsvp: rsvpRef.current }}
                        onJoinClick={() => {
                          setActiveStep(1);
                        }}
                      />
                    )}
                    {activeStep === 1 && (
                      <RsvpFormPreview
                        record={{ ...eventRef.current, rsvp: rsvpRef.current }}
                        onBackClick={() => {
                          setActiveStep(0);
                        }}
                        onSubmitClick={() => {
                          const result = document?.getElementById('rForm')?.reportValidity();
                          if (result) {
                            setActiveStep(2);
                          }
                        }}
                      />
                    )}
                    {activeStep === 2 && (
                      <RsvpFormPreview
                        isConfirmMode
                        record={{ ...eventRef.current, rsvp: rsvpRef.current }}
                        onBackClick={() => {
                          setActiveStep(1);
                        }}
                        onSubmitClick={(formData) => {
                          const filterEmptyValue = Object.fromEntries(
                            Object.entries(formData).filter(
                              ([_, v]) => typeof v === 'boolean' || (v && v.length > 0)
                            )
                          );
                          const emailTypeField = rsvpRef.current.fields.find(
                            (it) => it.type === 'email'
                          );
                          // console.log('emailTypeField', emailTypeField);
                          if (!emailTypeField) {
                            alert(t('register. error-fail-email-not-found'));
                            return;
                          }
                          const email = filterEmptyValue[emailTypeField.key];
                          if (rsvpRef.current?.payment?.enabled) {
                            API.postUserRegisterPayment(params.eventId, email, filterEmptyValue)
                              .then((resp) => {
                                registeredSuccessResponse.current = resp.data;
                                setActiveStep(4);
                              })
                              .catch((err) => {
                                if (err?.response?.status === 409) {
                                  alert(t('register.error-email-registered'));
                                  return;
                                }
                                alert(t('register.error-fail-register'));
                              });
                          } else {
                            API.postUserRegister(params.eventId, email, filterEmptyValue)
                              .then((resp) => {
                                registeredSuccessResponse.current = resp.data;
                                setActiveStep(3);
                              })
                              .catch((err) => {
                                if (err?.response?.status === 409) {
                                  alert(t('register.error-email-registered'));
                                  return;
                                }
                                alert(t('register.error-fail-register'));
                              });
                          }
                        }}
                      />
                    )}
                    {activeStep === 3 && (
                      <RsvpFormFinish
                        record={{ ...eventRef.current, rsvp: rsvpRef.current }}
                        registeredSuccessResponse={registeredSuccessResponse.current}
                      />
                    )}
                  </Box>
                  {activeStep === 4 && (
                    <Box>
                      <RsvpFormPayment
                        record={{ ...eventRef.current, rsvp: rsvpRef.current }}
                        registeredSuccessResponse={registeredSuccessResponse.current}
                      />
                    </Box>
                  )}
                </FormProvider>
              </Box>
            </ThemeProvider>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
