import React, { useEffect, useState, Suspense, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './styles/scss/app.scss';
import Home from './routes/home';
import Event from './routes/event';
import Register from './routes/register';
import Embed from './routes/embed';
import { checkLocalStorageAvailability, restoreBrowserSessionIdentifier } from 'redux/appSlice';
import PaymentReturn from './routes/paymentReturn';
import * as API from './api/baseAPI';
import { login, logout } from './redux/authSlice';
import { initGAUA } from 'redux/gaUaSlice';
import { Auth } from 'aws-amplify';
import logger from 'utils/loggerUtils';
import Fade from '@mui/material/Fade';
import MessageDialog from 'components/messageDialog';
import { DialogContext } from 'components/dialogContext';
import Layout from 'components/layout';
import LoginDialog from 'components/loginDialog';

const defaultCustomConfig = {
  enableLocale: false,
  defaultLocale: null,
  availableLocales: ['en', 'zh-Hans', 'zh-Hant'],
  restrictHeaderWidth: true,
  logoImageUrl: null,
  logoImageUrlMobile: null,
  logoLink: null,
  hideFooter: false,
  hideFooterLink: false,
  footerImageUrl: null,
  footerText: null,
  footerLink: null,
};

export default function App() {
  const dispatch = useDispatch();
  const { firstEventLoaded, localStorageAvailable, globalLoading } = useSelector(
    (state) => state.app
  );
  const { i18n } = useTranslation();
  const [eventValidated, setEventValidated] = useState(false);
  const [exitValidatedSession, setExitValidatedSession] = useState(false);
  const customization = useSelector((state) => state.event.customization);
  const [customStyle, setCustomStyle] = useState({});
  const [customConfig, setCustomConfig] = useState(defaultCustomConfig);
  const [dialogState, setDialogState] = useState({
    showDialog: false,
    message: '',
    closeHandler: () => {},
  });
  const [loginDialogState, setLoginDialogState] = useState({
    showDialog: false,
    closeHandler: () => {},
    successHandler: () => {},
  });

  const openDialog = useCallback((message, closeHandler) => {
    setDialogState((prevState) => ({
      ...prevState,
      showDialog: true,
      message: message,
      closeHandler: closeHandler,
    }));
  }, []);

  const closeDialog = useCallback(() => {
    setDialogState((prevState) => ({
      ...prevState,
      showDialog: false,
      message: '',
      closeHandler: () => {},
    }));
  }, []);

  const openLoginDialog = useCallback((closeHandler, successHandler) => {
    setLoginDialogState((prevState) => ({
      ...prevState,
      showDialog: true,
      closeHandler: closeHandler,
      successHandler: successHandler,
    }));
  }, []);

  const closeLoginDialog = useCallback(() => {
    setLoginDialogState((prevState) => ({
      ...prevState,
      showDialog: false,
      closeHandler: () => {},
    }));
  }, []);

  const resizeHandler = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  const eventValidatedHandler = useCallback((value) => {
    setEventValidated(value);
  }, []);

  const exitHandler = () => {
    setExitValidatedSession(true);
  };

  const logoutHandler = async () => {
    try {
      await Auth.signOut();
      dispatch(logout());
    } catch (err) {
      logger.log(err);
    }
  };

  const loginHandler = () => {
    openLoginDialog(
      () => {
        closeLoginDialog();
      },
      () => {
        closeLoginDialog();
      }
    );
  };

  useEffect(() => {
    if (exitValidatedSession) {
      setExitValidatedSession(false);
    }
  }, [exitValidatedSession]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeHandler);
    }
    if (typeof document !== 'undefined') {
      resizeHandler();
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', resizeHandler);
      }
    };
  }, []);

  useEffect(() => {
    setCustomStyle({});
    setCustomConfig(defaultCustomConfig);
    if (customization.general) {
      if (customization.general.style) {
        let customStyle = {};
        for (const [key, value] of Object.entries(customization.general.style)) {
          customStyle[key] = value;
        }
        setCustomStyle(customStyle);
      }
      if (customization.general.config) {
        setCustomConfig((customConfig) => ({
          ...customConfig,
          ...customization.general.config,
        }));
        if (customization.general.config.defaultLocale) {
          const url = window.location.href;
          let containLocaleQuery = false;
          if (url.indexOf('?locale=') !== -1) containLocaleQuery = true;
          else if (url.indexOf('&locale=') !== -1) containLocaleQuery = true;
          if (!containLocaleQuery) {
            i18n.changeLanguage(customization.general.config.defaultLocale);
          }
        }
      }
    }
  }, [customization, i18n]);

  useEffect(() => {
    dispatch(checkLocalStorageAvailability());
    if (process.env.REACT_APP_GA_UA_TRACKING_ID && process.env.REACT_APP_GA_UA_TRACKING_ID !== '') {
      dispatch(initGAUA());
    }

    const authCheck = async () => {
      try {
        await Auth.currentSession();
        let response = await API.authGetMe();
        dispatch(login(response.data.data));
      } catch (err) {
        logger.log(err);
      }
    };
    authCheck();
  }, [dispatch]);

  useEffect(() => {
    if (localStorageAvailable === true) {
      dispatch(restoreBrowserSessionIdentifier());
    }
  }, [dispatch, localStorageAvailable]);

  return (
    <Suspense fallback="loading">
      <DialogContext.Provider
        value={{
          openDialog: openDialog,
          closeDialog: closeDialog,
          openLogin: openLoginDialog,
          closeLogin: closeLoginDialog,
        }}
      >
        <Fade in={!globalLoading || firstEventLoaded}>
          <div className="app" style={customStyle}>
            <Routes>
              <Route path="register/:eventId" element={<Register />} />
              <Route path="register/:eventId/return" element={<PaymentReturn />} />
              <Route
                path="/"
                element={
                  <Layout
                    customConfig={customConfig}
                    eventValidated={eventValidated}
                    exitHandler={exitHandler}
                    loginHandler={loginHandler}
                    logoutHandler={logoutHandler}
                  />
                }
              >
                <Route index element={<Home />} />
                <Route
                  path="event/:eventId"
                  element={
                    <Event
                      eventValidatedHandler={eventValidatedHandler}
                      exitValidatedSession={exitValidatedSession}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <main>
                      <div style={{ padding: '10px' }}>
                        <p>Page Not Found!</p>
                      </div>
                    </main>
                  }
                />
              </Route>
              <Route
                path="embed/:eventId"
                element={
                  <Embed
                    eventValidatedHandler={eventValidatedHandler}
                    exitValidatedSession={exitValidatedSession}
                  />
                }
              />
            </Routes>
            <MessageDialog
              open={dialogState.showDialog}
              message={dialogState.message}
              handleClose={dialogState.closeHandler}
            />
            <LoginDialog
              open={loginDialogState.showDialog}
              handleClose={loginDialogState.closeHandler}
              successHandler={loginDialogState.successHandler}
            />
          </div>
        </Fade>
      </DialogContext.Provider>
    </Suspense>
  );
}
